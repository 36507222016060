
.layout {
  display: flex;

  @media (--pgn-size-breakpoint-max-width-lg) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (--pgn-size-breakpoint-min-width-xl) {
    justify-content: space-between;
  }
}

.content {
  @media (--pgn-size-breakpoint-min-width-xl) {
    display: flex;
    justify-content: center;
    width: 50vw;
    margin-top: 4rem;
  }
}

.logo-small {
  width: 44.67px;
  margin-top: 1.25rem;
  margin-left: 1.5rem;
}

.logo {
  width: 71px;
  margin-top: 2rem;
  margin-left: 1.5rem;
}

.mw-320 {
  max-width: 320px;
}

.main-heading {
  position: relative;
  z-index: 2;
}

.complete-your-profile {
  font-weight: 700;
  line-height: 1;

  @media (--pgn-size-breakpoint-max-width-xl) {
    font-size: 3.75rem;
  }

  @media (min-width: 1400px) {
    font-size: 4.875rem;
  }
}

.welcome-to-platform {
  margin-bottom: 0.5rem;
  font-weight: 700;

  @media (--pgn-size-breakpoint-max-width-xl) {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }

  @media (min-width: 1400px) {
    font-size: 2rem;
    line-height: 2.25rem;
  }
}

.large-screen-left-container {
  @media (--pgn-size-breakpoint-max-width-xl) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 1400px) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.small-screen-top-stripe {
  height: 0.25rem;
  background-image: linear-gradient(
    102.02deg,
    var(--pgn-color-brand-700),
    var(--pgn-color-brand-700) 20%,
    var(--pgn-color-brand-base) 20%,
  );
  background-repeat: no-repeat;
}

@media (--pgn-size-breakpoint-min-width-md) and (--pgn-size-breakpoint-max-width-md) {
  .medium-screen-top-stripe {
    display: flex;
    height: 0.5rem;
    background-image: linear-gradient(
      102.02deg,
      var(--pgn-color-brand-700),
      var(--pgn-color-brand-700) 10%,
      var(--pgn-color-brand-base) 10%,
      var(--pgn-color-brand-base) 90%,
      var(--pgn-color-primary-700) 90%,
      var(--pgn-color-primary-700) 100%,
    );
    background-repeat: no-repeat;
  }
}

@media (--pgn-size-breakpoint-min-width-lg) and (--pgn-size-breakpoint-max-width-lg) {
  .medium-screen-top-stripe {
    display: flex;
    height: 0.5rem;
    background-image: linear-gradient(
      102.02deg,
      var(--pgn-color-brand-700) 10%,
      var(--pgn-color-brand-base) 10%,
      var(--pgn-color-brand-base) 65%,
      var(--pgn-color-primary-700) 65%,
      var(--pgn-color-primary-700) 75%,
      var(--pgn-color-accent-a) 75%,
      var(--pgn-color-accent-a) 75%
    );
    background-repeat: no-repeat;
  }
}

.extra-large-screen-top-stripe { display: none; }

@media (--pgn-size-breakpoint-min-width-xl) {
 .extra-large-screen-top-stripe {
   display: flex;
   height: 0.5rem;
   background-image: linear-gradient(
     102.02deg,
     var(--pgn-color-brand-700) 10%,
     var(--pgn-color-brand-base) 10%,
     var(--pgn-color-brand-base) 45%,
     var(--pgn-color-primary-700) 45%,
     var(--pgn-color-primary-700) 55%,
     var(--pgn-color-accent-a) 55%,
     var(--pgn-color-accent-a) 75%,
     var(--pgn-color-info-200) 75%,
  );
   background-repeat: no-repeat;
 }
}

.large-screen-svg-light,
.large-screen-svg-primary {
  fill: var(--pgn-color-light-200);
  overflow: hidden;
  position: absolute;
}

.large-screen-svg-primary {
  fill: var(--pgn-color-primary-400);
}

.medium-screen-svg-light,
.medium-screen-svg-primary {
  fill: var(--pgn-color-light-200);
  overflow: inherit;
  position: absolute;
}

.medium-screen-svg-primary {
  fill: var(--pgn-color-primary-400);
}

[dir=rtl]{
  .medium-screen-svg-light,
  .medium-screen-svg-primary,
  .large-screen-svg-light,
  .large-screen-svg-primary {
    transform: scaleX(-1);
  }
}

.small-yellow-line {
  width: 80px;
  height: 0;
  border: 2px solid var(--pgn-color-accent-b);
  transform: rotate(102.02deg);
}

.medium-yellow-line {
  width: 120px;
  height: 0;
  border: 3px solid var(--pgn-color-accent-b);
  transform: rotate(102.02deg);
}

.large-yellow-line {
  width: 240px;
  height: 0;
  border: 3px solid var(--pgn-color-accent-b);
  transform: rotate(102.02deg);
}
